<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a-select
          :options="[
            { value: null, label: 'Semua Status' },
            { value: 1, label: 'Aktif' },
            { value: 0, label: 'Tidak Aktif' },
          ]"
          class="mr-2"
          style="width: 300px;"
          placeholder="Semua Status"
          v-model:value="active"
        ></a-select>
        <a-input-search v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button class="m-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="mr-2"
          title="Tambah Uom"
          type="primary"
          @click="
            () => {
              formState = {}
              formState.status_approval = 0
              formState.type = 1
              modal1Visible = true
              formState.title_state = 'Tambah Uom'
              formState.state = 'new'
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="table-responsive ">
      <md-table
        :columns="columns"
        :data-source="data"
        :row-selection="rowSelection"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #status="{ record }">
          <a-tag v-if="record.active" color="#108ee9">{{ record.status_text }}</a-tag>
          <a-tag v-else color="grey">{{ record.status_text }}</a-tag>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Ubah Area">
              <a
                href="javascript: void(0);"
                class="btn  btn-sm btn-light"
                @click="editKonversi(record.id)"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
    <a-modal v-model:visible="modal1Visible" :title="formState.title_state" @ok="modal1Visible = false">
      <template #footer>
        <a-button key="submit" type="primary" @click="submit" >Simpan</a-button>
      </template>
       <a-form :wrapper-col="{ span: 14 }">
            <a-row class="form-row" style="margin-top: -20px;">
              <a-col :sm="24">
                <a-form-item
                  label="Nama UoM"
                  label-align="left"
                  :label-col="{ sm: { span: 7 } }"
                  :wrapper-col="{ sm: { span: 24 - 7 } }"
                 >
                  <a-select
                    :options="[
                      { value: null, label: 'Semua' },
                      { value: 1, label: 'TON' },
                      { value: 1, label: 'SAK 25 KG' },
                      { value: 1, label: 'Pcs' },
                      { value: 1, label: 'Dozen 40' },
                      { value: 1, label: 'HG' },
                      { value: 1, label: 'cm' },
                      { value: 1, label: 'Kuintal' },
                      { value: 1, label: 'ZAK 50 Kg' },
                      { value: 1, label: 'ZAK 30 Kg' },
                      { value: 1, label: 'M3' },
                      { value: 1, label: 'SAK 50 Kg' },
                      { value: 1, label: 'SAK 40 Kg' },
                    ]"
                    class="mt-2"
                    stylea="width: 300px;"
                    placeholder="Nama UoM"
                    v-model:value="formState.name"
        ></a-select>
                </a-form-item>
             </a-col>
            </a-row>
            <a-row class="form-row" style="margin-top: -20px;">
              <a-col :sm="24">
                <a-form-item
                  label="Value"
                  label-align="left"
                  :label-col="{ sm: { span: 7 } }"
                  :wrapper-col="{ sm: { span: 24 - 7 } }"
                 >
                  <a-input placeholder="Value" v-model:value="value"/>
                </a-form-item>
             </a-col>
            </a-row>
            <a-row class="form-row" style="margin-top: -20px;">
              <a-col :sm="24">
                <a-form-item
                  label="UoM Konversi"
                  label-align="left"
                  :label-col="{ sm: { span: 7 } }"
                  :wrapper-col="{ sm: { span: 24 - 7 } }"
                 >
                  <a-select
                    :options="[
                      { value: null, label: 'Semua' },
                      { value: 1, label: 'TON' },
                      { value: 1, label: 'SAK 25 KG' },
                      { value: 1, label: 'Pcs' },
                      { value: 1, label: 'Dozen 40' },
                      { value: 1, label: 'HG' },
                      { value: 1, label: 'cm' },
                      { value: 1, label: 'Kuintal' },
                      { value: 1, label: 'ZAK 50 Kg' },
                      { value: 1, label: 'ZAK 30 Kg' },
                      { value: 1, label: 'M3' },
                      { value: 1, label: 'SAK 50 Kg' },
                      { value: 1, label: 'SAK 40 Kg' },
                    ]"
                    class="mt-2"
                    stylea="width: 300px;"
                    placeholder="UoM Konveris"
                    v-model:value="formState.konversi"
              ></a-select>
                </a-form-item>
             </a-col>
            </a-row>
             <a-row class="form-row" style="margin-top: -20px;">
              <a-col :sm="24">
                <a-form-item
                  label="Value Konversi"
                  label-align="left"
                  :label-col="{ sm: { span: 7 } }"
                  :wrapper-col="{ sm: { span: 24 - 7 } }"
                 >
                  <a-input placeholder="Value Konversi" v-model:value="value"/>
                </a-form-item>
             </a-col>
            </a-row>
            <a-row class="form-row" style="margin-top: -20px;">
              <a-col :sm="24">
                <a-form-item
                  label="Status"
                  label-align="left"
                  :label-col="{ sm: { span: 7 } }"
                  :wrapper-col="{ sm: { span: 24 - 7 } }"
                 >
                 <a-radio-group placeholder="Status" width="100%" v-model:value="formState.active">
                <a-radio :value="true"> Aktif </a-radio>
                <a-radio :value="false"> Tidak Aktif </a-radio>
                </a-radio-group>
                </a-form-item>
             </a-col>
            </a-row>
       </a-form>
  </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
const acolumns = [
  {
    title: 'NO',
     slots: { customRender: 'no' },
  },

{
    title: 'Nilai',
    dataIndex: 'nilai',
    align: 'center',
  },

  {
    title: 'UOM',
    dataIndex: 'uom',
  },

 {
    title: 'Nilai Konversi',
    dataIndex: 'conversion',
    align: 'center',
  },

 {
    title: 'UOM Konversi',
    dataIndex: 'description',
    align: 'center',
  },

  {
    title: 'Status',
    slots: { customRender: 'status' },
    align: 'center',
  },

  {
    title: 'Action',
    slots: { customRender: 'action' },
    align: 'center',
  },
]

export default defineComponent({
  name: 'VbKonversiSatuan',
  components: {
  },
  setup() {
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const loading = ref(false)
    const modal1Visible = ref(false)
    const formState = ref({})
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    //const data = ref({})
    const current1 = ref(1)

    const data = ref([])
    const q = ref('')
    const kabupaten = ref([])
    const active = ref(null)
    const isPosting = ref(false)
    const value1 = ref(true)
    const value2 = ref(false)

    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }

    columns.value = acolumns

    // const params = ref({})
    // const fetchData = product_id => {
    //   const _params = {
    //     page: page.value,
    //     'per-page': perPage.value,
    //     q: q.value,
    //     active: active.value,
    //   }
    //   params.value = Object.assign({}, _params)
    //   state.isFetching = true
    //   apiClient
    //     .get(`/api/products/${product_id}/uoms`, {
    //       params: _params,
    //     })
    //     .then(response => {
    //       const { items, _meta } = response.data
    //       data.value = items
    //       meta.value = _meta || { pageCount: 0, totalCount: 0 }
    //       pageCount.value = meta.value.pageCount
    //       totalCount.value = meta.value.totalCount
    //     })
    //     .finally(() => {
    //       state.isFetching = false
    //     })
    // }

    // const editKonversi = product_id => {
    //   apiClient
    //     .get(`/api/products/${product_id}/uoms`)
    //     .then(response => {
    //       const data = response.data
    //       formState.value = data
    //       modal1Visible.value = true
    //       formState.value.title_state = 'Edit Konversi'
    //       formState.value.state = 'edit'
    //       formState.value.readOnly = false
    //     })
    //     .catch(error => console.error(error))
    // }



    provide('formState', formState)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
    })

    const startRow = ref(1)
    const search = () => {
      fetchData()
    }

    onMounted(() => {
      // fetchData()
    })

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    const handleOk = () => {
      loading.value = true
      setTimeout(() => {
        loading.value = false
        visible.value = false
      }, 2000)
    }
    const visible = ref(false)

    return {
     modal1Visible,
      loading,
      visible,
      handleOk,
      formState,
      setModal1Visible,
      data,
      columns,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      searchText,
      searchInput,
      q,
      search,
      startRow,
      state,
      ...toRefs(state),
      // fetchData,
      handleTableChange,
      // modalRef,
      handleReset,
      handleSearch,
      onSelectChange,
      active,
      // editKonversi,
      value1,
      value2,
    }
  },
})
</script>
<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
